/** @format */

import React from "react";
import Wrapper from "./Welcome.styled";

// import images and icons

function Welcome() {
  return (
    <Wrapper>
      <div className='about' id='about'>
        <div className='container'>
          <div className='row'>
            {/* single item start here */}
            <div className='col-md-4'>
              <div className='leftSIngleItem'>
                <div className='leftContent'>
                  <h2 style={{ color: '#FFFF00' }}>Fair Launch and Distribution</h2>
                </div>
                <div className='boxShadow'></div>
              </div>
            </div>
            {/* single item end here */}
            {/* right item start here */}
            <div className='col-md-8 aboutCOl'>
              <div className='rigtContentSingleItem'>
                <div className='rightContent'>
                  {/* <h2>Catch Them While You Can…</h2> */}
                  <br></br>
                  <p>The project was developed at the inception of creating Pulsechain. The AAC has remained steadfast for launch and we are thrilled “We are live”. Congratulations to all teh believers and the the Pulschain.com and PulseX.com development teams.  The NFT’s and tokens have been developed to add maximum value for all involved, now and into the future.</p> 
                  <br></br> <p>Designed to grow in utility and thus in value as minting completes. With your tokens, you can redeem them and Mint your NFT or keep them and Mint with PLS. 
                  </p>
                  <br></br> <p>You will mint your own AAC here and will be the first and only holder of a one of a kind unique Adolescent Ape NFT with utility. 
                  </p>
                </div>
                <div className='boxShadow2'></div>
              </div>
            </div>
            {/* right item end here */}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Welcome;
